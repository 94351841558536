import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const Terms = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(limit: 1, filter: { wordpress_id: { eq: 134 } }) {
        edges {
          node {
            title
            status
            content
            wordpress_id
            featured_media {
              source_url
            }
          }
        }
      }
    }
  `)

  return (
    <Layout
      featuredImg={
        data.allWordpressPage.edges[0].node.featured_media.source_url
      }
      pageName={data.allWordpressPage.edges[0].node.title} 
    >
      <section className="py-4 w-full lg:w-8/12 mx-auto">
        <div
          dangerouslySetInnerHTML={{
            __html: data.allWordpressPage.edges[0].node.content,
          }}
        ></div>
      </section>
      <Seo title={data.allWordpressPage.edges[0].node.title} description={data.allWordpressPage.edges[0].node.content} image={data.allWordpressPage.edges[0].node.featured_media.source_url} article={true} />
    </Layout>
  )
}

export default Terms
